<template>
  <div id="balance-history">
    <Headline />
    <AccountBalanceHistory />
  </div>
</template>

<script>
import Headline from '../../components/app/Headline'
import AccountBalanceHistory from '../../components/account/AccountBalanceHistory'

export default {
  components: {
    Headline,
    AccountBalanceHistory
  }
}
</script>
